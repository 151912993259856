@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Pacifico&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

.scroll-bar::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.scroll-bar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.scroll-bar::-webkit-scrollbar-thumb {
  background: #efeeee;
  border-radius: 5px;
}

/* Handle on hover */
.scroll-bar::-webkit-scrollbar-thumb:hover {
  background: #555;
}